import Title from '../components/Typography/Title';

export function NotFound() {
  return (
    <>
      <Title>
        존재하지 않는 페이지입니다.
      </Title>
    </>
  );
}
